import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import MobileMenu from 'src/components/MobileMenu'
import Clock from 'react-live-clock';

import { colors, typography, animations, mq, util } from 'src/styles'

const showHide = false // show and hide header on scroll
const headerHeight = (additionalHeight = 0) => util.responsiveStyles('height', (140 + additionalHeight), (130 + additionalHeight), (110 + additionalHeight), (75 + additionalHeight))
const headerHeightCollapsed = util.responsiveStyles('height', 80, 70, 66, 60)

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: ${ colors.mainColor };
  color: ${ colors.bgColor };
  ${ typography.bodySmall };
  font-weight: 600;
  letter-spacing: 0;
  padding: 10px 16px;
  visibility: hidden;
  opacity: 0;
  transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
  a {
    display: inline-block;
    padding: 3px 0;
    opacity: .6;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 5px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: ${ colors.mainColor };
      top: 50%;
      margin-top: -3px;
      opacity: 0;
      transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
    }
    &:hover {
      opacity: 1;
      &:after {
        opacity: 1;
        transform: rotate(-45deg) translate3d(5px, 5px, 0);
      }
    }
  }
`

const NavLinkStyle = (scrolled, active, hasAtf, dropdown) => `
	display: block;
	${ typography.h6 }
	line-height: 1em;
	transition: 	padding ${ animations.mediumSpeed } ease-in-out,
								margin ${ animations.mediumSpeed } ease-in-out,
                background ${ animations.mediumSpeed } ease-in-out,
								color ${ animations.mediumSpeed } ease-in-out;
	${ active ? `
    color: ${ colors.green };
		&:hover {
			color: ${ colors.green };
		}
	` : `` }
  &:hover {
    color: ${ colors.green };
  }
`

const NavLink = styled(Link)`
  ${ props => NavLinkStyle(props.scrolled, props.active, props.hasAtf, props.hasDropdown) }
`

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: height ${ animations.mediumSpeed } ease-in-out,
              background ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out,
              box-shadow ${ animations.mediumSpeed } ease-in-out;
  ${ ({ scrolled, hasAtf, mobileMenuOpen }) => scrolled ? `
		${ headerHeightCollapsed }
		background: ${ colors.black };
		color: ${ colors.textColor };
		box-shadow: 0 1px 0 ${ rgba(colors.textColor, 0.1) };
    ${ mq.mediumAndBelow } {
      ${ mobileMenuOpen ? `
        background: transparent;
        box-shadow: none;
        ${ headerHeight() }
      ` : `` }
    }
	` : `
		${ headerHeight() }
		background: transparent;
		${ hasAtf ? `
			color: ${ colors.bgColor };
		` : `
			color: ${ colors.textColor };
		` }
	` }
  ${ ({ navVisible }) => navVisible && `
		transform: translate3d(0, -101%, 0);
	` }
`

const HeaderContent = styled(Grid)``

const HeaderLogo = styled(Logo)`
	${ util.responsiveStyles('width', 80, 50, 50, 40) }
	height: auto;
	transition: color ${ animations.mediumSpeed } ease-in-out, width ${ animations.mediumSpeed } ease-in-out;
	${ ({ scrolled, hasAtf, mobileMenuOpen }) => scrolled ? `
		color: ${ colors.mainColor };
		${ util.responsiveStyles('width', 60, 40, 40, 30) }
    ${ mq.mediumAndBelow } {
      ${ mobileMenuOpen ? `
        ${ util.responsiveStyles('width', 80, 50, 50, 40) }
      ` : `` }
    }
	` : `
		${ !hasAtf ? `
			color: ${ colors.mainColor };
		` : `
			color: ${ colors.bgColor };
		` }
	` }
`

const LogoCol = styled.div`
	text-align: center;
	a {
		display: inline-block;
		vertical-align: top;
    transition: none;
    max-width: 100%;
	}
`

const NavLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: baseline;
  display: flex;
  width: 100%;
  justify-content: ${ ({ alignment }) => alignment === 'right' ? 'flex-end' : 'flex-start' };
  li {
    position: relative;
    flex-shrink: 0; 
    &:hover {
      ${ Dropdown } {
        visibility: visible;
        opacity: 1;
      }
    }
    &:not(:last-of-type) {
      ${ util.responsiveStyles('margin-right', 60, 40, 20, 20) }
    }
  }
`

const MenuIcon = styled.div`
	display: none;
  padding: 5px 10px;
  margin-left: -10px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  transition: color ${ animations.mediumSpeed } ease-in-out;
  span {
    display: block;
  }
`

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	transition: height ${ animations.mediumSpeed } ease-in-out;
	${ ({ hasBanner }) => hasBanner ? `
		${ headerHeight(40) }
	` : `
		${ headerHeight() }
	` }
`

const HeaderNotificationBanner = styled(NotificationBanner)`
	position: relative;
	z-index: 5;
`

const ClockWrapper = styled.div`
  .clock {
    font-family: ${ typography.secondaryFont };
}
`

const Header = ({
  location,
  hasAtf = false,
  bannerText,
  collapsed,
  bannerColor,
  navigation,
  title
}) => {
  const [ mobileMenuOpen, toggleMobileMenu ] = useState(false)
  const [ bannerVisible, toggleBanner ] = useState(false)

  let pathname = location
  let pageHasAtf = hasAtf && !mobileMenuOpen
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  console.log("Header Timezone:",Intl.DateTimeFormat().resolvedOptions().timeZone)
  return (
    <Fragment>
      <ScrollListener.Consumer>
        {({
          scrolledToTop,
          scrolledToBottom,
          scrollY,
          scrolledUp,
          hasScrolled,
          pageHeight
        }) => {
          let scrolled = false
          if (collapsed) {
            scrolled = true
          } else {
            scrolled = !scrolledToTop && hasScrolled
          }
          return (
            <Wrapper hasAtf={pageHasAtf} navVisible={!scrolledUp && !scrolledToTop && showHide}>
              {bannerText && (
                <HeaderNotificationBanner
                  closeBanner={toggleBanner(false)}
                  collapsed={!bannerVisible}
                  text={bannerText}
                  setTheme={bannerColor}
                />
              )}
              <HeaderWrapper
                navVisible={!scrolledUp && !scrolledToTop && showHide}
                hasAtf={pageHasAtf}
                scrolled={scrolled}
                mobileMenuOpen={mobileMenuOpen}
                navVisible={!scrolledUp && !scrolledToTop && showHide}
              >
                <HeaderContent
                  small="1 [3] [6] [3] 1"
                  medium="1 [5] [2] [5] 1"
                  large="1 [8] [8] [8] 1"
                  vAlign="center"
                  hasAtf={pageHasAtf}
                  navVisible={!scrolledUp && !scrolledToTop && showHide}
                >
                  <div>
                   <ClockWrapper>
                      <Clock className="clock" format={'HH:mm:ss zz'} ticking={true} timezone={timezone} />
                    </ClockWrapper>
                  </div>
                  <LogoCol>
                  </LogoCol>
                  <div>
                    <NavLinks alignment="right">
                      <ResponsiveComponent
                        small={
                          <MenuIcon id="mobile-menu-icon" onClick={() => toggleMobileMenu(!mobileMenuOpen)}>
                            <AnimatedIcon
                              icon={mobileMenuOpen ? 'close' : 'menu'}
                            />
                          </MenuIcon>
                        }
                        medium={navigation && navigation.map((link, index) => (
                          <li key={'header-link-' + link.id}>
                            <NavLink
                              scrolled={scrolled}
                              hasAtf={pageHasAtf}
                              to={'/' + link.to.slug}
                              active={pathname === link.to.slug}
                              key={link.to.slug}
                              hasDropdown={link.dropdownLinks}
                            >
                              {link.label}
                            </NavLink>
                            {link.dropdownLinks && (
                              <Dropdown>
                                {link.dropdownLinks.map((dropdownLink, index) => (
                                  <div>
                                    <Link to={'/' + dropdownLink.to.slug}>{dropdownLink.label}</Link>
                                  </div>
                                ))}
                              </Dropdown>
                            )}
                          </li>
                        ))}
                      />
                    </NavLinks>

                  </div>
                </HeaderContent>
              </HeaderWrapper>
            </Wrapper>
          )
        }}
      </ScrollListener.Consumer>

      {!hasAtf && (
        <HeaderPlaceholder hasBanner={bannerText && bannerVisible} />
      )}

      <ResponsiveComponent
        small={
          <MobileMenu
            open={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            navLinks={navigation}
          // footerColumn1={footerColumn1}
          // footerColumn2={footerColumn2}
          />
        }
        medium={<span />}
      />

    </Fragment>
  )
}

export default Header
